/** @jsx jsx */
import { jsx } from 'theme-ui';
import { NearshoringDevelopment } from '@composite';
import { Layout, StaticSEO } from '@core';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';

export default () => {
  const pageContent = useStrapiPageContent('nearshoring-development');
  return (
    <Layout>
      <StaticSEO pageId="nearshoring" />
      <NearshoringDevelopment pageContent={pageContent} />
    </Layout>
  );
};
